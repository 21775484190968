<template>
   <div class="admin-panel__dashboard">
      <AdminPanelDashboardMenu :data="menuItems" />
      <div class="admin-panel__dashboard__сontent">
         <div class="admin-panel__dashboard__title">
            <p class="admin-panel__dashboard__title-text">
               {{ textTitle }}
            </p>
         </div>
         <router-view></router-view>
      </div>
   </div>
</template>

<script>
import AdminPanelDashboardMenu from '../../components/AdminPanel/AdminPanelDashboardMenu.vue';

export default {
   name: 'AdminPanelDashboard',
   inject: ['checkIfErrorIsTokenNotFound'],
   components: {
      AdminPanelDashboardMenu,
   },

   computed: {
      textTitle() {
         return this.$route.params.name[0].toUpperCase() + this.$route.params.name.slice(1);
      },

      menuItems() {
         return this.$store.getters['admin/getMenuItems'];
      },
   },

   created() {
      this.loadResources();
   },

   methods: {
      loadResources() {
         this.$store.dispatch('admin/loadResources');
      },
   },
};
</script>

<style lang="scss">
.admin-panel__dashboard {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   background-color: var(--green-lighten-4);
   &__сontent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100vw;
      height: 100vh;
   }
   &__title {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--green-lighten-4);
      border-bottom: 1px solid var(--green-lighten-2);
      &-text {
         font-family: 'Roboto';
         font-size: 24px;
         font-weight: 700;
         color: var(--grey-darken-4);
      }
   }
}
</style>