<template>
   <v-btn class="admin_panel__dashboard__menu__item" @click="toName">
      {{ item.text }}
   </v-btn>
</template>

<script>
export default {
   name: 'AdminPanelDashboardMenuItem',
   props: {
      item: { type: Object },
      action: {
         type: Function,
      },
   },

   methods: {
      toName() {
         if (this.action) {
            this.action();
            return;
         }
         if (this.$route.params.name === this.name) return;

         this.$router.push({
            name: 'dashboard-item',
            params: {
               name: this.item.path,
            },
         });
      },
   },
};
</script>

<style lang="scss">
.admin_panel__dashboard__menu__item {
   width: 100%;
   padding: 20px 0 !important;
   border-radius: 0 !important;
   background-color: var(--green-lighten-2) !important;
   color: var(--grey-darken-4) !important;
   font-family: 'Roboto' !important;
   font-size: 16px !important;
   font-weight: 700 !important;
}
</style>