<template>
   <div class="admin_panel__dashboard__menu">
      <AdminPanelDashboardMenuItem v-for="item in data" :key="item.path" :item="item" />
      <AdminPanelDashboardMenuItem :item="{ text: 'Exit' }" :action="logOut" />
   </div>
</template>

<script>
import AdminPanelDashboardMenuItem from './AdminPanelDashboardMenuItem.vue';

export default {
   name: 'AdminPanelDashboardMenu',
   components: {
      AdminPanelDashboardMenuItem,
   },

   props: {
      data: { type: Array },
   },
   data() {
      return {};
   },

   methods: {
      logOut() {
         localStorage.token = '';
         sessionStorage.isAuth = false;
         this.$router.push({ name: 'admin-login' });
      },
   },
};
</script>

<style lang="scss">
.admin_panel__dashboard__menu {
   overflow: auto;
   padding: 10px 0;
   width: 30vw;
   max-width: 250px;
   height: 100vh;
   background-color: var(--green-lighten-3);
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   flex-shrink: 0;
   &__header {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      &__title {
         font-family: 'Roboto';
         font-size: 18px;
         font-weight: 500;
         color: var(--grey-darken-4);
         width: 100%;
         text-align: right;
         &-sub {
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 500;
            color: var(--grey-darken-1);
            width: 100%;
            text-align: right;
         }
      }
   }
}
</style>